import React from "react";
import "./footer.css";
import headerlogo from "../../images/headerlogo.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer className="footerbar">
        <div className="footer-section">
          <div className="first-section">
            <img src={headerlogo} alt="" className="footer-logo" />
          </div>
          <div className="second-section">
            <h1 className="connectus">Connect with us</h1>
            <div className="icons">
              <NavLink to="/facebook.com" className="ficon">
                <FacebookIcon />
              </NavLink>
              <NavLink to="https://www.youtube.com/@TheEduCloud" className="ficon">
                <YouTubeIcon />
              </NavLink>
              <NavLink to="/instagram" className="ficon">
                <InstagramIcon />
              </NavLink>
              <NavLink to="https://www.linkedin.com/in/fastest5-technology-b16a26280" className="ficon">
                <LinkedInIcon />
              </NavLink>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
