import React from 'react';
import ReactPlayer from 'react-player';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import "./video.css";

export const Video = () => {


  return (
    <div className='video-content'>
        <div className='video-text'> 
        <h1>DevOps Traning</h1>  
        <ol className='lists' type='1'>
         
          <li>Comprehensive Curriculum</li>
          <li>Experienced Instructors</li>
          <li> Hands-on Approach</li>
          <li> Industry-Relevant Skills </li>
          <li> Flexible Learning Options</li>
          <li>Supportive Learning Environment</li>
          <li> weekly assessment</li>
        </ol>



        </div>
        <div className='video-section'>
            <ReactPlayer
            url='https://youtu.be/Vi-zfKQD5JQ'
            width='100%'
            height='50vh' />
        </div>
        
    </div>
  )
}


export default Video;