import React from 'react'
import "./homebanner.css";

const HomeBanner = (props) => {
  return (
    <div className='banner'>
         <div>
            <h1 className='heading'>{props.DevOps}</h1>
            <p className='para'>{props.para}</p>
         </div>
         <div className='left-img'>
            <img src={props.img} alt="" className='Bimg'/>
         </div>
    </div>
  )
}


export default HomeBanner;