import React from 'react'
import Card from "../components/Crad/Card";



  


export const Courses = () => {
  return (
    <div className='courses'>
    
      <Card/>
    </div>
  )
}

export default Courses;