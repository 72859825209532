import React from "react";
import "./table.css";

export const Table = () => {
  return (
    <div className="table-content">
      <div className="table-heading">
        <h1>
          DevOps Classes <br /> schedules for Dec’23-jan’24(currently enrollment is
          going on)
        </h1>
      </div>
      <table className="table-text">
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Type</th>
          <th>When</th>
        </tr>
        <tr>
          <td>1st Feb </td>
          <td>
            09:00 AM to 11:00 AM
          </td>
          <td>Weekends</td>
          <td>Saturday/Sundays</td>
        </tr>
        <tr>
          <td>15th Feb </td>
          <td>6:00 to 8:00 PM </td>
          <td>Weekdays</td>
          <td>Monday to Friday</td>
        </tr>
      </table>
    </div>
  );
};

export default Table;
