import React from 'react'
import HomeBanner from "../components/HomeBanner/HomeBanner";
import Card from '../components/Crad/Card';
import ContactDetail from "../components/ContactDetail/ContactDetail";
import BenefitsSection from "../components/BenefitsSection/BenefitsSection";
// import CoursesCard from "../components/CoursesCard/CoursesCard";
// import FlipCard from '../components/FlipCard/FlipCard';
import Table from "../components/Table/Table";
import Slider from "../components/Slider/Slider";
import Video from "../components/Video/Video";


export const Home = () => {
  return (
    <>
    <Slider/>
    <Card/>
   
    <Table/>
    <Video/>
    <ContactDetail/>
    </>
  )
}


export default Home;