import React from 'react'
import ContactDetail from "../components/ContactDetail/ContactDetail";


export const ContactUs = () => {

const style ={
  marginTop: "1.2rem",
}

  return (
    <div style={style}>
    <ContactDetail/>
    </div>
  )
}

export default ContactUs;