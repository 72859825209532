import React from 'react'
import WhyChoose from "../components/WhyChoose/WhyChoose";

export const WhyChooseTheEduCloud = () => {



  return (
   
   <>
   <WhyChoose/>
   </>
  )
}


export default WhyChooseTheEduCloud;