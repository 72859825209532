import React from "react";
import "../App.css";
import Errorimg from "../images/Errorimg.png";
import { NavLink } from "react-router-dom";
import BlueBtn from "../components/Button/BlueBtn/BlueBtn";


const Error = () => {
  return (
      <div className="error-page">
      <img src={Errorimg} alt="error" className="error-img" />
      {/* <h1>Error page 404</h1> */}
     <div className="backbtn">
     <NavLink to="/">
       <BlueBtn text="Back To Home"/>
      </NavLink>
     </div>
      </div>
  );
};


export default Error;
