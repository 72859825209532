import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import bannerimg from "../../images/bannerimg.jpeg";
import azure from "../../images/azure.jpg";
import interview from "../../images/interview.jpg";
import project from "../../images/project.jpg";
import python from "../../images/python.jpg";
import aws from "../../images/aws.jpg";
import HomeBanner from "../HomeBanner/HomeBanner";
import "./slider.css";

export default class AutoPlay extends Component {
  render() {


const styled ={
  marging: "5rem",
}

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 8000,
      autoplaySpeed: 8000,
      cssEase: "linear",
    };
    return (
      <div>
        <Slider {...settings} style={styled}>
          <div>
            <HomeBanner DevOps="DevOps"  img={bannerimg}/>
          </div>
          <div>
          <HomeBanner DevOps="AWS-DevOps"  img={aws}/>
          </div>
          <div>
          <HomeBanner DevOps="Azure-DevOps"  img={azure}/>
          </div>
          <div>
          <HomeBanner DevOps="Python for DevOps"  img={python}/>
          </div>
          <div>
          <HomeBanner DevOps="DevOps interview preparation"  img={interview}/>
          </div>
          <div>
          <HomeBanner DevOps="DevOps projects" para="paragraph" img={project}/>
          </div>
        </Slider>
      </div>
    );
  }
}