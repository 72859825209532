import React from 'react'
import "./whychoose.css";

export const WhyChoose = () => {
  return (
    <div className='choose-text'>
        <h1 className='why-text'>WhyChooseEduCloud</h1>
      <div>
        <ol className='list-item'>
          <li>Comprehensive Curriculum</li>
          <li>Experienced Instructors</li>
          <li>Hands-on Approach</li>
          <li>Industry-Relevant Skills</li>
          <li>Flexible Learning Options</li>
          <li>Supportive Learning Environment</li>
          <li>weekly assessment</li>
        </ol>
      </div>

      </div>
  )
}

export default WhyChoose;