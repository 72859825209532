import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import "./bluebtn.css";


export default function BasicButtons(prop) {
  return (
    <Stack  direction="row" className='Blue-Btn'>
      <Button variant="contained">{prop.text}</Button>
    </Stack>
  );
}