import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3hj5elp",
        "template_h5jc2oq",
        form.current,
        "akXPDcp5pAcizXdCE"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("message sent successfully");
        },
        (error) => {
          alert(error.text);
        });
        e.target.reset();
  };

  return (
    <div className="contact-data">

       
      <form ref={form} onSubmit={sendEmail} className="form-data">
      <h1 className="cont-text">Contact Form</h1>
        <label className="label-text">Name</label>
        <input type="text" name="to_name"  className="input-text"/>
        <label className="label-text">Email</label>
        <input type="email" name="from_name" className="input-text" />
        <label className="label-text">Message</label>
        <input type="namber" name="message"  className="input-text"/>
        <input type="submit" value="Send"className="submitBtn" />
      </form>
      </div>
  );
};

export default Contact;


