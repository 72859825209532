import './App.css';
import React from "react";
import Home from './Pages/Home';
import Error from "./Pages/Error";
import Courses from "./Pages/Courses";
import ContactUs from "./Pages/ContactUs";
import About from './Pages/About';
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Blog from './Pages/Blog';
import WhyChooseTheEduCloud from "./Pages/WhyChooseTheEduCloud";


function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/whychoosetheeducloud" element={<WhyChooseTheEduCloud/>} />
          <Route path="*" element={<Error />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
