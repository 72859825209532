import React from 'react';
import "./contactdetail.css";
import whatsapp from "../../images/whatsapp.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import Contact from '../Contact/Contact';
import ReactWhatsapp from 'react-whatsapp';

import { NavLink } from "react-router-dom";

export const ContactDetail = () => {
  return (
    <div className='contact-detail'>
             <div className='contact-section'>
                <div className='contact-left-section'>
                  <h5 className='contact-heading'> CONTACT DETAILS</h5>
                  <h1 className='get-text'> Get in touch us</h1>
                  <div className='phon-text'>
                    <p  className='phone-number'> <span><PhoneIphoneIcon/>Get in touch us <br /> </span>+918770084913</p>
                     <p className='phone-number phonenumber'> <span><EmailIcon/>E-MAIL <br /></span>theeducloud.technology@gmail.com</p>
                    <div className='icons'>

                    <NavLink to="/facebook.com" className="ficon">
                <FacebookIcon />
              </NavLink>
              <NavLink to="https://www.youtube.com/@TheEduCloud" className="ficon">
                <YouTubeIcon />
              </NavLink>
              <NavLink to="/instagram" className="ficon">
                <InstagramIcon />
              </NavLink>
              <NavLink to="https://www.linkedin.com/in/fastest5-technology-b16a26280" className="ficon">
                <LinkedInIcon />
              </NavLink>



                    {/* <button className='ficon'>
                        <FacebookIcon />
                    </button>
                     <button className='ficon'>
                       <YouTubeIcon/>
                    </button>
                    <button className='ficon'>
                       <InstagramIcon/>
                   </button>
                    <button className='ficon'>
                       <LinkedInIcon/>
                    </button>  */}
                 </div>
                      <ReactWhatsapp number="+91 8770084913" massage="I am Interested" className='what-btn'><span className='wht-icon'>  <img src={whatsapp} alt="img" /></span></ReactWhatsapp>
                  </div>
                </div>
                <div className='contact-right-section'>
                 <Contact/>


                </div>

             </div>
    </div>
  )
}

export default ContactDetail; 