import * as React from 'react';
import bannerimg from "../../images/bannerimg.jpeg";
import aws from "../../images/aws.jpg";
import azure from "../../images/azure.jpg";
import python from "../../images/python.jpg";
import interview from "../../images/interview.jpg";
import project from "../../images/project.jpg";



import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SchoolIcon from '@mui/icons-material/School';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ScheduleIcon from '@mui/icons-material/Schedule';
import "./card.css";
import BttonArrow from "../Button/ButtonArrow/ButtonArrow";



export default function Card() {
  return (

     <div className="card-section">
       <div className='card-top-text'>
        <span>Live Trainings We Offer</span>
      </div>
      <div className='main-card'>
      <div className='cards'>
            <div className='card-heading'>
                <h1>DevOps <br />
                    Training</h1>
            </div> 
            <div className='card-img'> 
            <img src={bannerimg} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            <div className='cardBtn'>
              {/* <span><BttonArrow/></span> */}
              </div>

           </div>
           <div className='cards'>
            <div className='card-heading azure'>
                <h1>AWS-DevOps Training</h1>
            </div>
            <div className='card-img'> 
            <img src={aws} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            {/* <div className='cardBtn'>
              <span><BttonArrow/></span>
              </div> */}

           </div>
           <div className='cards'>
            <div className='card-heading data'>
                <h1>Azure-DevOps Training</h1>
            </div>
            <div className='card-img'> 
            <img src={azure} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            <div className='cardBtn'>
              {/* <span><BttonArrow/></span> */}
              </div>

           </div>
      </div>


      <div className='main-card secon-main-card'>
      <div className='cards'>
            <div className='card-heading'>
                <h1>Python for DevOps  <br />
                    Training</h1>
            </div> 
            <div className='card-img'> 
            <img src={project} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            <div className='cardBtn'>
              {/* <span><BttonArrow/></span> */}
              </div>

           </div>
           <div className='cards'>
            <div className='card-heading azure'>
                <h1>DevOps interview <br /> preparation Training</h1>
            </div>
            <div className='card-img'> 
            <img src={interview} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            {/* <div className='cardBtn'>
              <span><BttonArrow/></span>
              </div> */}

           </div>
           <div className='cards'>
            <div className='card-heading data'>
                <h1>DevOps projects <br /> Training</h1>
            </div>
            <div className='card-img'> 
            <img src={project} alt="" className='cardimg' />
            </div>
            <div className='card-text'>
            {/* <p> <span className='card-icons'><CheckCircleOutlineOutlinedIcon/></span>Suitable for Tech/Non-Tech Professionals </p>
            <p> <span className='card-icons'><SchoolIcon/></span>Become a DevOps Engineer </p>
            <p><span className='card-icons'><CoPresentIcon/></span>Instructor-Led training </p>
            <p> <span className='card-icons'><ScheduleIcon/></span>3+ Months Program </p> */}
            </div>
            {/* <div className='card-dev'>
            Valaxy DevOps Practitioner program will <br /> provide you with in-depth knowledge of <br /> various DevOps tools, including Linux, Git,<br /> GitHub, Bitbucket, Jenkins, Ansible,<br /> Terraform, Docker, Kubernetes,<br /> Prometheus, Grafana, Amazon Web <br /> Services (AWS)
            </div> */}

            <div className='cardBtn'>
              {/* <span><BttonArrow/></span> */}
              </div>

           </div>
      </div>





      <div className='cards-all-btn'>
        {/* <BasicButtons text="See All Courses"/> */}
      </div>

     </div>

   
  );
}